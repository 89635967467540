import { UtilsService } from '../../../../core/services/utils.service';
import {Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FindActivityService} from '../../../../core/services/find-activity.service';
import {DatePipe} from '@angular/common';
import {ConfirmationDialogComponent} from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../../../shared/models/dialog';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NewActivityService} from '../../../../core/services/new-activity.service';
import {StoreResponse} from '../../../../shared/models/activity/store-response.model';
import {Response} from '../../../../shared/models/activity/response.model';
import {Store} from '../../../../shared/models/activity/store.model';
import {ActivityConstants} from '../../../../shared/constants/ActivityConstants';
import {MsgBannerService} from '../../../../shared/components/msg-banner/msg-banner.service';
import {Location} from '../../../../shared/models/location.model';
import {Season} from '../../../../shared/models/season.model';
import {LoadResponsesComponent} from '../../../../shared/components/load-responses/load-responses.component';
import {MsgType} from '../../../../shared/components/msg-banner/msg-type';
import {Message} from '../../../../shared/components/msg-banner/message.model';
import {CustomFieldsValidators} from '../../../../shared/components/custom-validators/CustomFieldsValidators';
import {environment} from '../../../../../environments/environment';
import {Customer} from '../../../../shared/models/activity/customer.model';
import {ActivityType} from '../../../../shared/models/activity/activity-type.model';
import {ActivityStatusEnum} from '../../../../shared/models/activity/activity-status.model';
import {ActivityTypes} from '../../../../shared/constants/ActivityTypes';
import {StoreContainerConfig} from '../../../../shared/models/activity/store-container.config';
import {StoreContainerComponent} from '../../../../shared/components/store-container/store-container.component';
import {User} from '../../../../shared/models/activity/user.model';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {UserService} from '../../../../core/services/user.service';
import {AttachmentView} from '../../../../shared/models/attachment.config';
import {GenericAttachmentComponent} from '../../../../shared/components/generic-attachment/generic-attachment.component';
import {DefaultResponse} from '../../../../shared/models/activity/default-response.model';
import {AdminService} from '../../../../core/services/admin.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {GraphService} from '../../../../core/graph/graph.service';
import { ActivityGroupView } from '../../../../shared/models/activity/activity-view.model';
import { Activity } from '../../../../shared/models/activity/activity.model';
import { ActivityGroupUpdates } from '../../../../shared/models/activity/activity-group.model';

const CONFIG = {
  toolbar: [
    [ 'Bold', 'Italic', 'Underline', '-', 'NumberedList', 'BulletedList' ]
  ],
  width: '100%',
  height: '100px',
  allowedContent: false,
  forcePasteAsPlainText: true,
  removePlugins: ['exportpdf', 'elementspath', 'magicline'],
  extraPlugins: 'wordcount',
  wordcount: {
    showParagraphs: false,
    showWordCount: false,
    showCharCount: true,
    countSpacesAsChars: false,
    countHTML: false,
    maxWordCount: -1,
    maxCharCount: environment.lengthValidators.description,
  },
  removeButtons: 'Source,Save,NewPage,ExportPdf,Preview,Print,Templates,Cut,Copy,Paste,PasteText,'             +
    'PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,'        +
    'Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,'    +
    'Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Link,'    +
    'Unlink,Image,Flash,Table,Smiley,SpecialChar,PageBreak,Iframe,Styles,TextColor,Maximize,ShowBlocks,BGColor,' +
    'About,Format,Font,FontSize,HorizontalRule,Anchor'
};

@Component({
  selector: 'app-find-activity',
  templateUrl: './activity-detail.component.html',
  styleUrls: ['./activity-detail.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'void',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ActivityDetailComponent implements OnInit {
  @ViewChild(LoadResponsesComponent) child: LoadResponsesComponent;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild(StoreContainerComponent) storeContainer: StoreContainerComponent;
  @ViewChild(GenericAttachmentComponent) attachmentContainer: GenericAttachmentComponent;

  // error list
  messageList = [];
  showNotification = false;

  // info list
  infoList = [];
  showInfo = false;

  // Forms
  formDates: FormGroup;

  // Tables data sources
  answerSource = new MatTableDataSource<Response>();
  dataSource = new MatTableDataSource<Customer>();
  backupDataSource: Customer[] = null;

  // Table headers
  activityStatuses = ActivityConstants.ACTIVITY_STATUS.filter(a => a.disabled === false);

  // Conditions
  public showSearchResults = true;
  isPersonalActivity = false;
  loadResponses = false;

  // Results
  public results: any;

  // Angular upgrade
  public value2;
  public selected;
  massEdit;
  haveTheSameActivityGroupId = false;
  config = CONFIG;

  // dropdowns
  locations: Location[] = [];
  seasons: Season[] = [];
  activityTypes: ActivityType[] = [];
  responses: DefaultResponse[] = [];
  activityChainGroupId: string;

  filteredUsers: User[] = [];
  isLoading = false;

  filterArgs = { active: true };

  attachments: string[] = [];
  isUrl = false;

  terrs: string[] = [];

  // chips
  separatorKeysCodes: number[] = [ENTER, COMMA];
  users: User[] = [];
  selectable = true;
  removable = true;
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;

  @ViewChildren('hideContent') content: QueryList<ElementRef>;

  isExpansionDetailRow = (i: number, row: any) =>
    row.hasOwnProperty('detailRow')

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    public activityLoadPageFindService: FindActivityService,
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public activityDetailDialogRef: MatDialogRef<ActivityDetailComponent>,
    public dialog: MatDialog,
    private ukBranchService: NewActivityService,
    private findActivityService: FindActivityService,
    private msgBanner: MsgBannerService,
    private adminService: AdminService,
    private graphService: GraphService
  ) {
    this.createForm();
  }



  ngOnInit() {
    this.addBannerMsg(
      'Changing responses will affect the entire activity group responses!',
      MsgType.INFO
    );

    this.loadTaskType();
    this.loadLocation();
    this.loadSeason();
    this.findTerrs(this.graphService.user.displayName);

    if (this.data.page === 'manage-group') {
      this.massEdit = false;
      this.getGroupDefaultValues();
      this.loadResponses = true;
      this.loadDefaultResponses();

    } else if (typeof this.data.taskId === 'string') {
      this.massEdit = false;
      this.searchActivities(this.data.taskId);
      this.formDates.controls.taskType.disable();
      this.loadResponses = true;
      if (this.data.page === 'publish') {
        this.loadDefaultResponses();
      }

    } else if (this.data.taskId.length > 1) {
      if (this.checkForMatch(this.data.taskId, 'actGrp', this.data.taskId[0].actGrp)) {
        this.haveTheSameActivityGroupId = true;
      } else {
        this.getResponses(this.data.taskId[0].actGrp);
      }
      if (this.checkAllArePersonalActivities(this.data.taskId, 'usrId')) {
        this.isPersonalActivity = true;
        this.enablePersonResponsibleAutocomplete();
      }
      this.massEdit = true;
      this.removeValidators();
    }
  }

  createForm() {
    this.formDates = this.fb.group({
      title: [null, [Validators.required, Validators.pattern(environment.emojiRegEx), CustomFieldsValidators.maxTitle]],
      fullAccount: ['', Validators.required, Validators.pattern(environment.emojiRegEx)],
      storeName: ['', Validators.required, Validators.pattern(environment.emojiRegEx)],
      desc: [null, [Validators.required, Validators.pattern(environment.emojiRegEx), CustomFieldsValidators.maxDescription]],
      activityStatus: ['', Validators.required],
      startDate: ['' , [Validators.required, Validators.pattern(environment.emojiRegEx)]],
      endDate: ['', Validators.required],
      expirationDate: ['', Validators.required],
      taskType: ['', Validators.required],
      season: ['', Validators.required],
      location: ['', Validators.required],
      personResponsible: ['', Validators.required],
      shipperCount: ['', Validators.required],
      createdBy: [''],
      publishedBy: ['']
    });
  }

  removeValidators() {
    this.formDates.controls.title.setValidators([CustomFieldsValidators.maxTitle]);
    this.formDates.controls.desc.setValidators([CustomFieldsValidators.maxDescription, Validators.pattern(environment.emojiRegEx)]);
    this.formDates.controls.activityStatus.setValidators(null);
    this.formDates.controls.startDate.setValidators(null);
    this.formDates.controls.endDate.setValidators(null);
    this.formDates.controls.expirationDate.setValidators(null);
    this.formDates.controls.season.setValidators(null);
    this.formDates.controls.location.setValidators(null);
    this.formDates.controls.personResponsible.setValidators(null);
  }

  getGroupDefaultValues() {
    this.showSearchResults = false;
    this.showNotification = false;

    this.findActivityService.getActivitiesByGroupId(this.data.taskId).subscribe(
      (response: ActivityGroupView) => {
        // activity group info
        if (response.activityView === null) {
          this.getResults({body: []});
        } else {
          this.getResults({body: response.activityView});
        }

        const usr: User[] = [];
        if (this.results.usrId) {
          usr.push({nam: this.results.nam, usrId: this.results.usrId});
        }
        this.users = usr;

        // customers
        if (response.customers === null) {
          this.dataSource = new MatTableDataSource<Customer>([]);
          this.backupDataSource = [];
        } else {
          this.dataSource = new MatTableDataSource<Customer>(response.customers);
          this.backupDataSource = JSON.parse(JSON.stringify(response.customers));
        }

        this.showSearchResults = true;
        this.activityChainGroupId = this.results.chainGrpId ? this.results.chainGrpId : null;
      },

      (error) => {
        this.dataSource = new MatTableDataSource<Customer>([]);
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  findTerrs(displayName: string) {
    this.adminService.getTerrsByEmail(displayName).subscribe(
      response => {
        this.terrs = response;
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  loadDefaultResponses() {
    this.showNotification = false;
    this.adminService.findAll().subscribe(
      result => {
        this.responses = result;
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  enablePersonResponsibleAutocomplete() {
    this.formDates.controls.personResponsible.valueChanges.pipe(
      debounceTime(300),
      tap(),
      switchMap(value => {
          if (value?.length < 3) {
            this.filteredUsers = [];
            return of(null);
          }
          if (value instanceof Object || value === '' || !value) {
            return of(null);
          }

          return this.userService.getUsers(value, this.terrs).pipe(
            finalize(() => this.isLoading = false)
          );
        }
      )
    ).subscribe(users => {
      if (((users && users.length === 0) || !users) && this.formDates.controls.personResponsible.value?.length >= 3) {
        this.close();
      }
      this.filteredUsers = users;
    });
  }

  private getResponses(activityGroupId: string) {
    this.findActivityService.getResponses(activityGroupId).subscribe(
      response => {
        this.loadResponses = true;
        this.answerSource = new MatTableDataSource<Response>(response);
      }, error => {
        this.loadResponses = true;
        this.answerSource = new MatTableDataSource<Response>();

        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  checkAllArePersonalActivities(array, propertyToMatch) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][propertyToMatch] === null) {
        return false;
      }
    }
    return true;
  }

  checkForMatch(array, propertyToMatch, valueToMatch) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][propertyToMatch] !== valueToMatch) {
        return true;
      }
    }
    return false;
  }

  addBannerMsg(text: string, type: MsgType) {
    this.showInfo = true;
    const errMsg = new Message();
    errMsg.content = text;
    errMsg.type = type;
    errMsg.disabled = false;

    if (
      this.infoList.filter((item) => item.content === errMsg.content)
        .length === 0
    ) {
      this.infoList = this.infoList.concat([errMsg]);
    }
  }

  loadTaskType() {
    this.showNotification = false;
    this.ukBranchService.getActivityType().subscribe(
      result => {
        this.activityTypes = result;
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  loadLocation() {
    this.showNotification = false;
    this.ukBranchService.getLocations().subscribe(
      result => {
        this.locations = result;
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  loadSeason() {
    this.showNotification = false;
    this.ukBranchService.getSeasons().subscribe(
      result => {
        this.seasons = result;
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  onChange($event: any): void {
    console.log('onChange');
  }

  onPaste($event: any): void {
    console.log('onPaste');
  }

  getResults(data: any): void {
    if (data.body.length === 0) {
      this.msgBanner.addMessage(this.messageList, 'No results found!', MsgType.INFO);
      this.showNotification = true;
    } else {
      this.results = data.body;
      this.changePage();
    }
  }

  openDialog(message: string, showYes: boolean, isLoading: boolean, showNo: boolean): any {
    return this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: new Dialog(message, showYes, isLoading, showNo),
      disableClose: true,
    });
  }

  searchActivities(id: string): void {
    this.showSearchResults = false;
    this.showNotification = false;
    const taskStatus = this.data.page === 'manage' ? ActivityStatusEnum.OPEN : null;
    this.activityLoadPageFindService.getActivities(id, taskStatus).subscribe(
      response => {
        this.getResults(response);
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, 'This task does not exist!');
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  isLater(dateString1: string, dateString2: string): any {
    return dateString1 > dateString2;
  }

  changePage(): void {
    // Identify if start date and end date passed
    const currentDate: string = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.formDates = this.fb.group({
      title: new FormControl({
        value: this.results.title,
        disabled: false
      }, [Validators.required, Validators.pattern(environment.emojiRegEx), CustomFieldsValidators.maxTitle]),
      fullAccount: new FormControl({
        value: '',
        disabled: true
      }),
      storeName: new FormControl({
        value: '',
        disabled: true
      }),
      desc: this.results.desc,
      activityStatus: this.results.sta,
      startDate: new FormControl({
        value: UtilsService.dateFromServer(this.results.start),
        disabled: false,
      }, [Validators.pattern(environment.emojiRegEx)]),
      endDate: new FormControl({
        value: UtilsService.dateFromServer(this.results.end),
        disabled: false,
      }, [Validators.pattern(environment.emojiRegEx)]),
      expirationDate: new FormControl({
        value: UtilsService.dateFromServer(this.results.exp),
        disabled: false
      }),
      taskType: this.results.actType,
      season: this.results.seas,
      location: this.results.loc,
      personResponsible: this.data.page === 'publish' ? this.results.usr : new User(this.results?.usrNam, this.results?.usrId),
      shipperCount: this.results.shprCt,
      createdBy: new FormControl({
        value: this.results?.createdBy,
        disabled: true
      }),
      publishedBy: new FormControl({
        value: this.results?.publishedBy,
        disabled: true
      }),
    });

    if (this.results.attach) {
      this.attachments = this.results.attach;
      this.isUrl = this.results.attach.length > 0 ? this.results.attach[0]?.includes('http') : false;
    }

    switch (this.results.sta) {
      case ActivityStatusEnum.OPEN:
        this.activityStatuses = ActivityConstants.OPEN_ACTIVITIES.filter(a => a.disabled === false);
        break;
      case ActivityStatusEnum.PENDING:
        this.activityStatuses = ActivityConstants.PENDING_ACTIVITIES.filter(a => a.disabled === false);
        break;
      case ActivityStatusEnum.COMPLETE:
        this.activityStatuses = ActivityConstants.COMPLETE_ACTIVITIES.filter(a => a.disabled === false);
        break;
      case ActivityStatusEnum.CANCELED:
        this.activityStatuses = ActivityConstants.CANCELED_ACTIVITIES.filter(a => a.disabled === false);
        break;
    }

    if (this.results.actType === ActivityTypes.PERSONAL) {
      this.isPersonalActivity = true;
      this.formDates.controls.season.setValidators(null);
      this.formDates.controls.location.setValidators(null);
    } else {
      this.isPersonalActivity = false;
      this.formDates.controls.personResponsible.setValidators(null);
    }

    this.formDates.controls.desc.setValidators([Validators.required,
      CustomFieldsValidators.maxDescription, Validators.pattern(environment.emojiRegEx)]);

    // Populate the array of responses for the second table, if they exist
    if (this.results.resps) {
      let responses: Response[] = [];
      responses = this.results.resps;
      this.answerSource.data = responses;
    } else {
      this.answerSource.data = [];
    }

    if (this.data.page === 'manage' && !this.isPersonalActivity) {
      this.formDates.controls.fullAccount.setValue(this.results.chain + this.results.str);
      this.getStoreName(this.results.chain, this.results.str);
    }

    if (this.data.page === 'publish') {
      this.activityChainGroupId = this.results.chainGrpId ? this.results.chainGrpId : null;

      let customers: Customer[] = [];
      if (this.results.custs) {
        customers = this.results.custs;
        this.dataSource = new MatTableDataSource<Customer>(customers);
      } else {
        this.dataSource = new MatTableDataSource<Customer>(customers);
      }

      let usr: User[] = [];
      if (this.results.usr) {
        usr = this.results.usr;
      }
      this.users = usr;
    }

    if (this.data.page !== 'manage-group') {
      this.showSearchResults = true;
    }

    this.enablePersonResponsibleAutocomplete();
  }

  private getStoreName(chain: string, str: string) {
    this.findActivityService.getStoreName(chain, str).subscribe(
      response => {
        this.formDates.controls.storeName.setValue(response);
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, JSON.parse(error.error).message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  private checkShipperCountValidity() {
    for (const store of this.dataSource.data) {
      if (!store.shprCt || store.shprCt.toString().trim() === '' || typeof store.shprCt !== 'number') {
        return true;
      }
    }
    return false;
  }

  updateActivity() {
    this.dataSource = new MatTableDataSource<Customer>(this.storeContainer.dataSource.data);
    this.showNotification = false;

    let startDate: string;
    if (this.formDates.controls.startDate.value) {
      startDate = this.formDates.controls.startDate.value;
    } else {
      startDate = this.results.startDate;
    }

    let endDate: string;
    if (this.formDates.controls.endDate.value) {
      endDate = this.formDates.controls.endDate.value;
    } else {
      endDate = this.results.endDate;
    }

    if (this.data.page === 'publish' && this.isPersonalActivity && (!this.users || this.users?.length === 0)) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: new Dialog('Please add at least one person responsible.', false, false),
        disableClose: true,
      });
      return;
    }

    if (this.checkShipperCountValidity() && this.formDates.controls.taskType.value === ActivityTypes.SHIPPER_COUNT) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: new Dialog(`Please complete the ${environment.shipperName.toLowerCase()} field for all stores.`, false, false),
        disableClose: true,
      });
      return;
    }

    if (this.formDates.invalid) {
      this.msgBanner.addMsgError(this.messageList, 'Please complete all required fields!');
      this.showNotification = true;
      return;
    }

    if (startDate != null && endDate != null) {
      const dialogRef = this.openDialog('', false, true, false);

      this.results.title = this.formDates.controls.title.value;
      this.results.desc = this.formDates.controls.desc.value;
      this.results.sta = this.formDates.controls.activityStatus.value;
      this.results.start = UtilsService.dateToServerDate(this.convertDate(this.formDates.value.startDate));
      this.results.end = UtilsService.dateToServerDate(this.convertDate(this.formDates.value.endDate));
      this.results.exp = UtilsService.dateToServerDate(this.convertDate(this.formDates.value.expirationDate));
      this.results.actType = this.activityTypes.find(t => t.actTypeId === this.formDates.controls.taskType.value).actTypeId;
      this.results.seas = this.seasons.find(s => s.seasId === this.formDates.controls.season.value)?.seasId;
      this.results.loc = this.locations.find(l => l.locId === this.formDates.controls.location.value)?.locId;
      this.results.shprCt = this.formDates.controls.shipperCount?.value;
      this.results.resps = this.child.dataSource.data;
      this.results.attach = this.attachmentContainer.attachments &&
        this.attachmentContainer.attachments.length > 0 ? this.attachmentContainer.attachments : null;
      this.results.chainGrpId = this.storeContainer.chainGroup && this.storeContainer.chainGroup.groupId ?
                this.storeContainer.chainGroup.groupId : null;

      if (this.data.page === 'manage' && this.isPersonalActivity) {
        this.results.usrId = this.formDates.controls.personResponsible?.value.usrId;
        this.results.usrNam = this.formDates.controls.personResponsible?.value.nam;
      }

      if (this.data.page === 'publish' && !this.isPersonalActivity) {
        this.results.custs = this.dataSource.data;
      } else if (this.data.page === 'publish' && this.isPersonalActivity) {
        this.results.usr = this.users;
      }
      const activityStatus = this.data.page === 'manage' ? ActivityStatusEnum.OPEN.valueOf() : null;

      if (this.data.page === 'manage-group' && !this.isPersonalActivity) {
        this.results.custs = this.dataSource.data;
      } else if (this.data.page === 'manage-group' && this.isPersonalActivity) {
        this.results.usr = this.users;
      }

      if (this.dataSource.data.find(str => str.sta === 7)) {
        const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '450px',
          data: new Dialog('You are creating an activity for a suspended store. Do you proceed?', true, false, true),
          disableClose: true,
        });

        confirmationDialogRef.afterClosed().subscribe(resp => {
          if (resp === true) {
            this.data.page === 'manage-group' ?
                        this.updateActivityGroup(dialogRef) :
                        this.update(activityStatus, dialogRef);
          } else {
            dialogRef.close();
            return;
          }
        });
      } else {
        this.data.page === 'manage-group' ?
                  this.updateActivityGroup(dialogRef) :
                  this.update(activityStatus, dialogRef);
      }
    } else {
      this.msgBanner.addMsgError(this.messageList, 'Invalid dates!');
      this.showNotification = true;
    }

  }

  update(activityStatus, dialogRef) {
    let actId: number = null;
    if (this.data.page === 'publish') {
      actId = this.results.actId;
    }

    this.ukBranchService.getActivities(this.results.title, this.results.chainGrpId,
                                      this.results.actGrp, actId).subscribe(
      (result) => {
        this.sendUpdate(activityStatus, dialogRef);

      }, error => {
        if (error.status === 302) {
          this.msgBanner.addMsgError(this.messageList, 'Activity name already exists!');
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
        dialogRef.close();
      });

  }

  sendUpdate(activityStatus, dialogRef) {
    this.activityLoadPageFindService.updateActivity(this.results, activityStatus).subscribe(
      (response) => {
        dialogRef.close();
        this.activityDetailDialogRef.close(response);
      }, error => {
        if (error.status === 404 || error.status === 400) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
        dialogRef.close();
        // this.activityDetailDialogRef.close(false);
      });
  }

  // deletes publish activity
  deletePublishActivity() { // delete the entire activity
    this.showNotification = false;
    const dialogRef = this.openDialog(
      'Do you want to delete this activity?',
      true,
      false,
      true
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const taskIds: string[] = [];
        if (typeof this.data.taskId === 'string') {
          taskIds.push(this.data.taskId);
        } else {
          for (let i = 0; i < this.data.taskId.length; i++) {
            taskIds.push(this.data.taskId[i].taskId);
          }
        }

        this.activityLoadPageFindService.deleteActivity(taskIds).subscribe(
          (response) => {
            this.showSearchResults = false;

            this.openDialog('Activities successfully deleted!', false, false, false);
            this.activityDetailDialogRef.close(taskIds);
          }, error => {
            this.msgBanner.addMsgError(this.messageList, error.error.message);
            this.showNotification = true;
          });
      }
    });
  }

  createStore(element: Store): StoreResponse {
    return {
      fullAccount: element.fullAccount,
      storeCode: element.storeCode,
      branchCode: element.branchCode,
      storeOwnRef: element.storeOwnRef,
      address: element.address,
      territory: element.territory,
      createdBy: null,
      createdAt: null,
      updatedBy: null,
      updatedAt: null,
      respId: null,
      comment: null,
      image: null
    };
  }

  massUpdate(form: any) {
    this.showNotification = false;
    this.dataSource = new MatTableDataSource<Customer>(this.storeContainer.dataSource.data);
    Object.keys(form).map(function (key) {
      if (form[key] === undefined || form[key] === '') {
        form[key] = null;
      }
    });

    const failForUpdate: number[] = [];

    for (let i = 0; i < this.data.taskId.length; i++) {
      if (form.title != null) { this.data.taskId[i].title = form.title; }
      if (form.desc != null) { this.data.taskId[i].desc = form.desc; }
      if (form.startDate != null) { this.data.taskId[i].start =  UtilsService.dateToServerDate(this.convertDate(form.startDate)); }
      if (form.endDate != null) { this.data.taskId[i].end =  UtilsService.dateToServerDate(this.convertDate(form.endDate)); }
      if (form.expirationDate != null) { this.data.taskId[i].exp =  UtilsService.dateToServerDate(this.convertDate(form.expirationDate)); }
      if (form.location != null) { this.data.taskId[i].loc = form.location.locId; }
      if (form.season != null) { this.data.taskId[i].seas = form.season.seasId; }
      if (this.attachmentContainer.attachments && this.attachmentContainer.attachments.length > 0) {
        this.data.taskId[i].attach = this.attachmentContainer.attachments;
      }
      if (form.personResponsible != null) {
        this.data.taskId[i].usrId = form.personResponsible.usrId;
        this.data.taskId[i].usrNam = form.personResponsible.nam;
      }

      if (form.activityStatus != null) {
        if (this.data.taskId[i].sta === ActivityStatusEnum.OPEN
          && !ActivityConstants.OPEN_ACTIVITIES.map(a => a.id).includes(form.activityStatus)) {
          failForUpdate.push(this.data.taskId[i].id.split('_')[1]);
        } else if (this.data.taskId[i].sta === ActivityStatusEnum.PENDING
          && !ActivityConstants.PENDING_ACTIVITIES.map(a => a.id).includes(form.activityStatus)) {
          failForUpdate.push(this.data.taskId[i].id.split('_')[1]);
        } else if (this.data.taskId[i].sta === ActivityStatusEnum.COMPLETE
          && !ActivityConstants.COMPLETE_ACTIVITIES.map(a => a.id).includes(form.activityStatus)) {
          failForUpdate.push(this.data.taskId[i].id.split('_')[1]);
        } else if (this.data.taskId[i].sta === ActivityStatusEnum.CANCELED
          && !ActivityConstants.CANCELED_ACTIVITIES.map(a => a.id).includes(form.activityStatus)) {
          failForUpdate.push(this.data.taskId[i].id.split('_')[1]);
        } else {
          this.data.taskId[i].sta = form.activityStatus;
        }
      }

      if (!this.haveTheSameActivityGroupId) {
        this.answerSource = this.child.dataSource;
        if (this.answerSource.data.length > 0) { this.data.taskId[i].resps = this.answerSource.data; }
      }
    }

    if (failForUpdate.length > 0) {
      const ids = failForUpdate.toString();
      this.msgBanner.addMsgError(this.messageList, (failForUpdate.length === 1 ? 'Activity ' : 'Activities ') + ids
        + ' cannot be updated to status of ' + ActivityConstants.ACTIVITY_STATUS.find(a => a.id === form.activityStatus)?.name);
      this.showNotification = true;
      return;
    }

    if (this.haveTheSameActivityGroupId) {
      this.sendMassUpdate();
      return;
    }

    // mass update with the same actGrp => check title for duplicates
    const act: Activity = this.data.taskId[0];
    this.ukBranchService.getActivities(act.title, null, act.actGrp, null).subscribe(
      (result) => {
        this.sendMassUpdate();

      }, error => {
        if (error.status === 302) {
          this.msgBanner.addMsgError(this.messageList, 'Activity name already exists!');
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      });
  }

  sendMassUpdate() {
    this.findActivityService.massUpdate(this.data.taskId, !this.haveTheSameActivityGroupId).subscribe(
      response => {
        this.activityDetailDialogRef.close(response);
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  buildConfig() {
    if (this.massEdit && this.haveTheSameActivityGroupId) {
      return {
        disableActions: false,
        updatable: true,
        data: null
      };
    }
    return {
      disableActions: false,
      updatable: true,
      data: this.answerSource.data
    };
  }

  cancel() {
    const dialogRef = this.openDialog(
      'Do you want to cancel your modifications?',
      true,
      false,
      true
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.activityDetailDialogRef.close();
      }
    });
  }

  getLength() {
    return environment.lengthValidators;
  }

  showError(error: string) {
    this.msgBanner.addMsgError(this.messageList, error);
    this.showNotification = true;
  }

  buildStoreConfig(): StoreContainerConfig {
    return {
      data: this.dataSource.data,
      page: this.data.page,
      activityType: this.formDates.controls.taskType.value,
      isPersonalActivity: this.isPersonalActivity,
      massEdit: this.massEdit,
      chainGroupId: this.activityChainGroupId
    };
  }

  getShipperCountValue() {
    return ActivityTypes.SHIPPER_COUNT;
  }

  valueMapper(user: User): string {
    return user?.nam;
  }

  buildAttachmentConfig() {
    return {
      view: AttachmentView.ACTIVITY,
      isArchived: false,
      limit: 1
    };
  }

  displayError(error: string) {
    if (error == null) {
      this.showNotification = false;
      return;
    }
    this.msgBanner.addMsgError(this.messageList, error);
    this.showNotification = true;
  }

  convertDate(value: any) {
    if (value instanceof Date) {
      return value;
    }
    return UtilsService.dateFromServer(value.toDate());
  }

  sendDataToChild(responses: Response[]) {
    this.child.config = {
      updatable: true,
      disableActions: false,
      data: responses
    };
    this.child.ngOnInit();
  }

  remove(usr: User): void {
    const index = this.users.indexOf(usr);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
  }

  selectedValue(event: MatAutocompleteSelectedEvent): void {
    const usr: User = {
      nam: event.option.value.nam,
      usrId: event.option.value.usrId
    };
    if (this.users.findIndex(p => p.usrId === usr.usrId) === -1) {
      this.users.push(usr);
      this.userInput.nativeElement.value = '';
      this.formDates.controls.personResponsible.setValue(null);
    }
  }

  close() {
    this.userInput.nativeElement.value = '';
    this.formDates.controls.personResponsible.setValue(null);
    this.formDates.controls.personResponsible.updateValueAndValidity();
  }

  removeOnFocusOut() {
    if (this.formDates.controls.personResponsible.value?.length <= 2) {
      this.close();
    }
  }

  closeDialog() {
    this.activityDetailDialogRef.close(null);
  }

  get getEnvironment() {
    return environment;
  }

  get getActivityTypes() {
    return ActivityTypes;
  }

  updateActivityGroup(dialogRef) {
    const act: Activity = JSON.parse(JSON.stringify(this.results));

    // update existing activities' details
    const updateInfo: ActivityGroupUpdates = {
      actGrp: act.actGrp,
      chainGrpId: act.chainGrpId,
      actType: act.actType,
      title: act.title,
      desc: act.desc,
      seas: act.seas,
      loc: act.loc,
      intUsr: act.intUsr,
      start: act.start,
      end: act.end,
      exp: act.exp,
      attach: act.attach,
      resps: act.resps,
      deleted: null,
      created: null,
      edited: null,
      createdBy: act.createdBy,
      publishedBy: act.publishedBy
    };

    // get created activities if the case
    updateInfo.created = this.getAddedStores();
    // get deleted activities if the case
    updateInfo.deleted = this.getRemovedStores();
    // get edited shipper count activites
    updateInfo.edited = this.getEditedShipperCountStores();

    this.ukBranchService.getActivities(updateInfo.title, updateInfo.chainGrpId, updateInfo.actGrp, null).subscribe(
      (result) => {
        this.sendUpdateActivityGroup(dialogRef, updateInfo);

      }, error => {
        if (error.status === 302) {
          this.msgBanner.addMsgError(this.messageList, 'Activity name already exists!');
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
        dialogRef.close();
      });
  }

  sendUpdateActivityGroup(dialogRef, updateInfo: ActivityGroupUpdates) {
    this.findActivityService.updateActivityGroup(updateInfo).subscribe(
      (result: any) => {
        dialogRef.close();
        this.activityDetailDialogRef.close(true);
      },
      (error) => {
        if (error.status === 404 || error.status === 400) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }

        this.showNotification = true;
        dialogRef.close();
      }
    );
  }

  getAddedStores() {
    // those that are in storeData, but not in backupData
    const storeData = this.storeContainer.dataSource.data;
    const filteredStores: any = storeData.filter(store =>
                    this.backupDataSource.find(b => b.str === store.str) === undefined);

    return filteredStores.map(c => ({
      chain: c.chain,
      str: c.str
    }));
  }

  getRemovedStores() {
    // those that are in backupData, but not in storeData
    const storeData = this.storeContainer.dataSource.data;
    const filteredStores: Customer[] = this.backupDataSource.filter(b =>
                    storeData.find(store => store.str === b.str) === undefined);
    return filteredStores.map(c => c.id);
  }

  getEditedShipperCountStores() {
    return Array.from(this.storeContainer.shipperCtChanges.values());
  }

  getCurrentActGrpId() {
    if (this.data.page === 'manage-group') {
      return this.data.taskId;
    } if (this.data.page === 'manage' && !this.massEdit) {
      return this.results.actGrp;
    } if (this.data.page === 'manage' && this.data.taskId.length > 1) {
      return this.data.taskId[0].actGrp;
    }

    return null;
  }

  // delete complaince activity group
  deleteActivityGroup() {
    // ask user for confirmation
    this.showNotification = false;
    const dialogRef = this.openDialog(
      'Do you want to delete this activity group?',
      true,
      false,
      true
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const loadingDialogRef = this.openDialog('', false, true, false);

        // delete activites
        const deletedIds: string[] = this.backupDataSource.map(c => c.id);
        this.findActivityService.deleteActivityFromGroup(deletedIds).subscribe(
          (response) => {
            loadingDialogRef.close();
            this.activityDetailDialogRef.close(true);
          },
          (error) => {
            loadingDialogRef.close();
            this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
            this.showNotification = true;
          }
        );
      }
    });
  }

  // delete personal activity
  deletePersonalActivity() {
    this.showNotification = false;
    const dialogRef = this.openDialog(
      'Do you want to delete this activity?',
      true,
      false,
      true
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result && typeof this.data.taskId === 'string') {
        const loadingDialogRef = this.openDialog('', false, true, false);

        const deletedIds: string[] = [this.data.taskId];
        this.findActivityService.deleteActivityFromGroup(deletedIds).subscribe(
          (response) => {
            loadingDialogRef.close();
            this.openDialog('Activity successfully deleted!', false, false, false);
            this.activityDetailDialogRef.close(true);
          },
          (error) => {
            loadingDialogRef.close();
            this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
            this.showNotification = true;
          }
        );
      }
    });
  }
}
