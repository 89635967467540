import { UtilsService } from '../../../../core/services/utils.service';
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NewActivityService} from '../../../../core/services/new-activity.service';
import {ConfirmationDialogComponent} from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../../../shared/models/dialog';
import {DatePipe, formatDate} from '@angular/common';
import {Activity} from '../../../../shared/models/activity/activity.model';
import {Response} from '../../../../shared/models/activity/response.model';
import {MsgBannerService} from '../../../../shared/components/msg-banner/msg-banner.service';
import {Location} from '../../../../shared/models/location.model';
import {Season} from '../../../../shared/models/season.model';
import {DefaultResponse} from '../../../../shared/models/activity/default-response.model';
import {AdminService} from '../../../../core/services/admin.service';
import {LoadResponsesComponent} from '../../../../shared/components/load-responses/load-responses.component';
import {PublishActivity} from '../../../../shared/models/activity/publish-activity.model';
import {MatSelectChange} from '@angular/material/select';
import {CustomFieldsValidators} from '../../../../shared/components/custom-validators/CustomFieldsValidators';
import {environment} from '../../../../../environments/environment';
import {Customer} from '../../../../shared/models/activity/customer.model';
import {ActivityTypes} from '../../../../shared/constants/ActivityTypes';
import {ActivityType} from '../../../../shared/models/activity/activity-type.model';
import {StoreContainerConfig} from '../../../../shared/models/activity/store-container.config';
import {StoreContainerComponent} from '../../../../shared/components/store-container/store-container.component';
import {of} from 'rxjs';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {AttachmentConfig, AttachmentView} from '../../../../shared/models/attachment.config';
import {GenericAttachmentComponent} from '../../../../shared/components/generic-attachment/generic-attachment.component';
import {User} from '../../../../shared/models/activity/user.model';
import {UserService} from '../../../../core/services/user.service';
import {AG_ROLE} from '../../../../shared/constants/roles';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {RoleGuardService} from '../../../../core/guards/role-guard.service';
import {GraphService} from '../../../../core/graph/graph.service';

const CONFIG = {
  toolbar: [
    [ 'Bold', 'Italic', 'Underline', '-', 'NumberedList', 'BulletedList' ]
  ],
  width: '100%',
  height: '100px',
  allowedContent: false,
  forcePasteAsPlainText: true,
  removePlugins: ['exportpdf', 'elementspath', 'magicline'],
  extraPlugins: 'wordcount',
  wordcount: {
    showParagraphs: false,
    showWordCount: false,
    showCharCount: true,
    countSpacesAsChars: false,
    countHTML: false,
    maxWordCount: -1,
    maxCharCount: environment.lengthValidators.description,
  },
  removeButtons: 'Source,Save,NewPage,ExportPdf,Preview,Print,Templates,Cut,Copy,Paste,PasteText,'             +
    'PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,'        +
    'Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,'    +
    'Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Link,'    +
    'Unlink,Image,Flash,Table,Smiley,SpecialChar,PageBreak,Iframe,Styles,TextColor,Maximize,ShowBlocks,BGColor,' +
    'About,Format,Font,FontSize,HorizontalRule,Anchor'
};

@Component({
  selector: 'app-new-activity',
  templateUrl: './new-activity.component.html',
  styleUrls: ['./new-activity.component.scss'],
})
export class NewActivityComponent implements OnInit {
  @ViewChild(LoadResponsesComponent) private child: LoadResponsesComponent;
  @ViewChild(StoreContainerComponent) storeContainer: StoreContainerComponent;
  @ViewChild(GenericAttachmentComponent) attachmentContainer: GenericAttachmentComponent;
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() load: any;
  form: FormGroup;
  formSearch: FormGroup;

  dataSource = new MatTableDataSource<Customer>();

  private activities: Activity[] = [];
  private activity: PublishActivity;
  public listOfResponses: Response[] = [];

  public submitted = false;
  isPersonalActivity = false;

  public storeAddButtonsDisabled = true;

  // error list
  messageList = [];
  showNotification = false;

  // dropdowns
  locations: Location[] = [];
  seasons: Season[] = [];
  activityType: ActivityType[] = [];
  responses: DefaultResponse[] = [];

  public selected;
  config = CONFIG;

  private nullValidators = [];
  private requiredValidators = [Validators.required];

  filteredUsers: User[] = [];
  isLoading = false;

  filterArgs = { active: true };

  // chips
  separatorKeysCodes: number[] = [ENTER, COMMA];
  users: User[] = [];
  selectable = true;
  removable = true;
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;

  attachmentConfig: AttachmentConfig = new AttachmentConfig();

  terrs: string[] = [];

  constructor(public newActivityService: NewActivityService,
              private userService: UserService,
              private adminService: AdminService,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private roleGuardService: RoleGuardService,
              private msgBanner: MsgBannerService,
              private graphService: GraphService,
              public datePipe: DatePipe) {
    this.createForms();
  }

  ngOnInit() {
    if (this.roleGuardService.userRoles.includes(AG_ROLE.UK_MERCH_ADMIN) || this.roleGuardService.userRoles.includes(AG_ROLE.JS_IT)
      || this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCH_ADMIN)) {
      this.attachmentConfig.view = AttachmentView.ACTIVITY;
    } else {
      this.attachmentConfig.view = AttachmentView.TEMP;
    }
    this.attachmentConfig.isArchived = false;
    this.attachmentConfig.limit = 1;

    this.form.controls.personResponsible.valueChanges.pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => {
          if (value?.length < 3) {
            this.filteredUsers = [];
            return of(null);
          }
          if (value instanceof Object || value === '' || !value) {
            return of(null);
          }

          return this.userService.getUsers(value, this.terrs).pipe(
            finalize(() => this.isLoading = false)
          );
        }
      )
    ).subscribe(users => {
      if (((users && users.length === 0) || !users) && this.form.controls.personResponsible.value?.length >= 3) {
        this.close();
      }
      this.filteredUsers = users;
    });

    this.loadActivityType();
    this.loadLocation();
    this.loadSeason();
    this.loadDefaultResponses();
    this.findTerrs(this.graphService.user.displayName);
  }

  findTerrs(displayName: string) {
    this.adminService.getTerrsByEmail(displayName).subscribe(
      response => {
        this.terrs = response;
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  loadDefaultResponses() {
    this.showNotification = false;
    this.adminService.findAll().subscribe(
      result => {
        this.responses = result;
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  loadActivityType() {
    this.showNotification = false;
    this.newActivityService.getActivityType().subscribe(
      result => {
        if (this.roleGuardService.userRoles.includes(AG_ROLE.UK_TERR_MANAGER)
          || this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCHANDISER_MANAGERS)) {
          this.activityType.push(result.find(a => a.actTypeId === ActivityTypes.PERSONAL.valueOf()));
          this.form.controls.taskType.setValue(ActivityTypes.PERSONAL.valueOf());
          this.form.controls.taskType.updateValueAndValidity();
          this.updateAttachmentConfig();
        } else if (this.roleGuardService.userRoles.includes(AG_ROLE.UK_ACCT_MANAGER)
          || this.roleGuardService.userRoles.includes(AG_ROLE.UK_CUST_OPS)
          || this.roleGuardService.userRoles.includes(AG_ROLE.JS_ACCT_MANAGER)) {
          this.activityType = result;
          const index = this.activityType.findIndex(a => a.actTypeId === ActivityTypes.PERSONAL.valueOf());
          if (index > -1) {
            this.activityType.splice(index, 1);
          }
        } else {
          this.activityType = result;
        }
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  loadLocation() {
    this.showNotification = false;
    this.newActivityService.getLocations().subscribe(
      result => {
        this.locations = result;
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  loadSeason() {
    this.showNotification = false;
    this.newActivityService.getSeasons().subscribe(
      result => {
        this.seasons = result;
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  onChange($event: any): void {
    console.log('onChange');
  }

  onPaste($event: any): void {
    console.log('onPaste');
  }

  createForms() {
    this.form = new FormGroup({
      activityName: new FormControl(null, [Validators.required, CustomFieldsValidators.maxTitle,
      Validators.pattern(environment.emojiRegEx)]),
      desc: new FormControl(null, [Validators.required, CustomFieldsValidators.maxDescription,
        Validators.pattern(environment.emojiRegEx)]),
      endDate: new FormControl(null, [
        Validators.required,
        this.endDateValidator,
      ]),
      startDate: new FormControl(null, [Validators.required]),
      expirationDate: new FormControl(null, [
        Validators.required,
        this.endDateValidator,
      ]),
      taskType: new FormControl(null, [Validators.required]),
      season: new FormControl(null, this.nullValidators),
      location: new FormControl(null, this.nullValidators),
      personResponsible: new FormControl(null, this.nullValidators),
      internalUseOnly: new FormControl(null, []),
      defaultSet: new FormControl(null, [])
    });

    this.formSearch = this.fb.group({
      storeCode: ['', Validators.required],
      address: ['', Validators.required],
      storeOwnRef: ['', Validators.required],
      territory: ['', Validators.required],
      selectControl: [''],
    });
  }

  get activityForm() {
    return this.form.controls;
  }

  isLater(dateString1: string, dateString2: string): any {
    return dateString1 > dateString2;
  }

  endDateValidator(control: FormControl) {
    if (!control.value) {
      return null;
    }
    const today: string = formatDate(new Date(), 'yyyy/MM/dd', 'en');
    const endDate: string = formatDate(control.value, 'yyyy/MM/dd', 'en');

    if (today > endDate) {
      return {
        endDateSmallerThanSysdate: {
          parsedDomain: today,
        },
      };
    }
    return null;
  }

  initializeArrays(): void {
    this.dataSource.data = [];
  }

  openConfirmationDialog(choice, data, showYesButton, selectionModel, variant, showNo): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: new Dialog(data, showYesButton, false, showNo),
      disableClose: true,
    });
  }

  resetFields() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: new Dialog(
        'Are you sure you want to reset all fields?',
        true,
        false
      ),
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onReset();
      }
    });
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
    this.initializeArrays();
    this.listOfResponses = [];

    this.form.controls.defaultSet.setValue([]);
    this.child.dataSource = new MatTableDataSource<Response>();
    this.child.showAnswers = false;
    this.child.form.reset();

    this.isPersonalActivity = false;

    this.form.controls.season.setValidators(this.requiredValidators);
    this.form.controls.location.setValidators(this.requiredValidators);
    this.form.controls.personResponsible.setValidators(this.nullValidators);

    this.form.controls.season.updateValueAndValidity();
    this.form.controls.location.updateValueAndValidity();
    this.form.controls.personResponsible.updateValueAndValidity();

    this.storeContainer.dataSource = new MatTableDataSource<Customer>();
    this.attachmentContainer.attachments = [];
    this.attachmentContainer.isUrl = false;
    this.users = [];
  }

  displayConfirmationMessage(message: string, variant: string) {
    this.openConfirmationDialog(
      null,
      message,
      false,
      null,
      variant,
      false
    );
  }

  private checkShipperCountValidity() {
    for (const store of this.dataSource.data) {
      if (!store.shprCt || store.shprCt.toString().trim() === '') {
        return true;
      }
    }
    return false;
  }

  onSend(child: any) {
    this.showNotification = false;
    this.messageList = [];

    this.submitted = true;
    this.dataSource = new MatTableDataSource<Customer>(this.storeContainer.dataSource.data);
    if (this.dataSource.data.length === 0 && this.form.controls.taskType.value !== ActivityTypes.PERSONAL) {
      this.displayConfirmationMessage(
        'Make sure you added at least one store to the list.', null);
      return;
    }
    if (this.form.invalid
      || (this.form.controls.taskType.value === ActivityTypes.PERSONAL && (!this.users || this.users?.length === 0))) {
      this.displayConfirmationMessage(
        'All required (*) fields must be completed and fields must not exceed maximum length.', null);
      return;
    }
    if (this.isLater(this.form.value['startDate'], this.form.value['endDate'])) {
      this.displayConfirmationMessage('StartDate is smaller that endDate.', null);
      return;
    }
    if (this.checkShipperCountValidity() && this.form.controls.taskType.value === ActivityTypes.SHIPPER_COUNT) {
      this.displayConfirmationMessage(`Please complete the ${environment.shipperName.toLowerCase()} field for all stores.`, null);
      return;
    }
    if (this.dataSource.data.find(str => str.sta === 7)) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: new Dialog('You are creating an activity for a suspended store. Do you proceed?', true, false, true),
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(resp => {
        if (resp === true) {
          this.listOfResponses = child.dataSource.data;
          this.send(child);
        } else {
          return;
        }
      });
    } else {
      this.listOfResponses = child.dataSource.data;
      this.send(child);
    }
  }

  send(child) {
    var chainGrpId: number = null;
    if (this.storeContainer.chainGroup != null && this.storeContainer.chainGroup != undefined)
      chainGrpId = this.storeContainer.chainGroup.groupId;

    this.newActivityService.getActivities(this.form.value['activityName'].trim(), chainGrpId, null, null).subscribe(
      (result) => {
        if (this.listOfResponses.length === 0) {
          this.displayConfirmationMessage('At least one response has to be added if you want the response to be mandatory.', null);
          return;
        }

        if (this.checkRoles()) {
          this.sendOpenActivities(child);
        } else {
          this.sendReviewActivities(child);
        }

      }, error => {
        if (error.status === 302) {
          this.msgBanner.addMsgError(this.messageList, 'Activity name already exists!');
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      });
  }

  private sendOpenActivities(child) {
    this.activities = this.computeActivities();

    this.newActivityService.createOpenActivity(this.activities).subscribe(
      (response) => {
        if (response != null) {
          this.displayConfirmationMessage('Activity created successfully!', null);
          this.onReset();
          child.reset();
        }
      });
  }

  private sendReviewActivities(child) {
    this.activity = this.computeActivity();

    this.newActivityService.createReviewActivity(this.activity).subscribe(
      (response) => {
        if (response != null) {
          this.displayConfirmationMessage('Activity created successfully!', null);
          this.onReset();
          child.reset();
        }
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      });
  }

  private computeActivities(): Activity[] {
    const activities: Activity[] = [];

    if (this.form.controls.taskType.value === ActivityTypes.PERSONAL) {
      for (let i = 0; i < this.users.length; i++) {
        activities.push(this.createActivity(null, this.users[i]));
      }
    } else {
      for (let i = 0; i < this.dataSource.data.length; i++) {
        activities.push(this.createActivity(this.dataSource.data[i], null));
      }
    }

    return activities;
  }

  private createActivity(data: Customer, usr: User): Activity {
      var act: Activity =  {
        id: null,
        actGrp: null,
        title: this.form.controls.activityName.value.trim(),
        desc: this.form.controls.desc.value.trim(),
        start: UtilsService.dateToServerDate(this.convertDate(this.form.controls.startDate.value)),
        end: UtilsService.dateToServerDate(this.convertDate(this.form.controls.endDate.value)),
        exp: UtilsService.dateToServerDate(this.convertDate(this.form.controls.expirationDate.value)),
        actType: this.form.controls.taskType.value,
        loc: this.form.controls.location.value,
        seas: this.form.controls.season.value,
        usrId: usr?.usrId,
        usrNam: usr?.nam,
        intUsr: this.form.controls.internalUseOnly.value == null ? false : this.form.controls.internalUseOnly.value,
        sta: null,
        attach: this.attachmentContainer.attachments &&
          this.attachmentContainer.attachments.length > 0 ? this.attachmentContainer.attachments : null,
        cmnt: null,
        imgs: null,
        resp: null,
        resps: this.listOfResponses,
        str: data?.str,
        chain: data?.chain,
        terr: data?.terr,
        stAdr: data?.stAdr,
        cty: data?.cty,
        region: data?.terr.substr(0, 2),
        strOwnRef: data?.strOwnRef,
        shprCt: this.form.controls.taskType.value === ActivityTypes.SHIPPER_COUNT ? data?.shprCt : null
      };

      if (this.storeContainer.chainGroup != null && this.storeContainer.chainGroup != undefined)
        act.chainGrpId = this.storeContainer.chainGroup.groupId;

      return act;
  }

  private computeActivity(): PublishActivity {
    var act: PublishActivity = {
      title: this.form.controls.activityName.value.trim(),
      desc: this.form.controls.desc.value.trim(),
      start: UtilsService.dateToServerDate(this.convertDate(this.form.controls.startDate.value)),
      end: UtilsService.dateToServerDate(this.convertDate(this.form.controls.endDate.value)),
      exp: UtilsService.dateToServerDate(this.convertDate(this.form.controls.expirationDate.value)),
      actType: this.form.controls.taskType.value,
      loc: this.form.controls.taskType.value === ActivityTypes.PERSONAL ? null : this.form.controls.location.value,
      seas: this.form.controls.taskType.value === ActivityTypes.PERSONAL ? null : this.form.controls.season.value,
      usr: this.isPersonalActivity ? this.users : null,
      intUsr: this.form.controls.internalUseOnly.value == null ? false : this.form.controls.internalUseOnly.value,
      sta: null,
      attach: this.attachmentContainer.attachments &&
        this.attachmentContainer.attachments.length > 0 ? this.attachmentContainer.attachments : null,
      resps: this.listOfResponses,
      custs: this.isPersonalActivity ? null : this.buildStores()
    };

    if (this.storeContainer.chainGroup != null && this.storeContainer.chainGroup != undefined)
        act.chainGrpId = this.storeContainer.chainGroup.groupId;

    return act;
  }

  buildConfig() {
    return {
      updatable: true,
      disableActions: false,
      data: null
    };
  }

  sendDataToChild(responses: Response[]) {
    this.child.config = {
      updatable: true,
      disableActions: false,
      data: responses
    };
    this.child.ngOnInit();
  }

  buildStores(): Customer[] {
    const stores: Customer[] = [];
    for (let i = 0; i < this.dataSource.data.length; i++) {
      stores.push({
        fullAcct: this.dataSource.data[i].fullAcct,
        nam: this.dataSource.data[i].nam,
        stAdr: this.dataSource.data[i].stAdr,
        cty: this.dataSource.data[i].cty,
        str: this.dataSource.data[i].str,
        chain: this.dataSource.data[i].chain,
        terr: this.dataSource.data[i].terr,
        strOwnRef: this.dataSource.data[i].strOwnRef,
        shprCt: this.form.controls.taskType.value === ActivityTypes.SHIPPER_COUNT ? this.dataSource.data[i].shprCt : null,
        sta: this.dataSource.data[i].sta
      });
    }

    return stores;
  }

  selectionChange(event: MatSelectChange) {
    if (event.value === ActivityTypes.SHIPPER_COUNT) {
      if (!this.storeContainer.displayedColumns.includes('shipperCount')) {
        this.storeContainer.displayedColumns = this.storeContainer.displayedColumns.concat('shipperCount');
      }
    } else {
      const index = this.storeContainer.displayedColumns.indexOf('shipperCount');
      if (index !== -1) {
        this.storeContainer.displayedColumns.splice(index, 1);
      }
    }

    if (event.value === ActivityTypes.PERSONAL) {
      this.storeContainer.dataSource = new MatTableDataSource<Customer>();
      this.isPersonalActivity = true;
      this.form.controls.season.setValidators(this.nullValidators);
      this.form.controls.location.setValidators(this.nullValidators);
    } else {
      this.isPersonalActivity = false;
      this.form.controls.season.setValidators(this.requiredValidators);
      this.form.controls.location.setValidators(this.requiredValidators);
    }

    this.form.controls.season.updateValueAndValidity();
    this.form.controls.location.updateValueAndValidity();

    this.updateAttachmentConfig();
  }

  updateAttachmentConfig() {
    if ((this.roleGuardService.userRoles.includes(AG_ROLE.UK_TERR_MANAGER) || this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCHANDISER_MANAGERS))
      && this.form.controls.taskType.value === ActivityTypes.PERSONAL.valueOf()) {
      if (this.form.controls.taskType.value === ActivityTypes.PERSONAL.valueOf()) {
        this.attachmentConfig.view = AttachmentView.ACTIVITY;
        this.attachmentContainer.attachments = [];
      } else {
        if (this.attachmentConfig.view !== AttachmentView.TEMP) {
          this.attachmentContainer.attachments = [];
        }
        this.attachmentConfig.view = AttachmentView.TEMP;
      }
    }
    this.attachmentConfig.isArchived = false;
    this.attachmentConfig.limit = 1;
  }

  getLength() {
    return environment.lengthValidators;
  }

  showError(error: string) {
    this.msgBanner.addMsgError(this.messageList, error);
    this.showNotification = true;
  }

  buildStoreConfig(): StoreContainerConfig {
    return {
      data: this.dataSource.data,
      page: 'publish',
      activityType: this.form.controls.taskType.value,
      isPersonalActivity: this.isPersonalActivity,
      massEdit: false
    };
  }

  valueMapper(user: User): string {
    return user?.nam;
  }

  getActivityType() {
    return ActivityTypes;
  }

  convertDate(value: any) {
    if (value instanceof Date) {
      return value;
    }
    return UtilsService.dateFromServer(value.toDate());
  }

  remove(usr: User): void {
    const index = this.users.indexOf(usr);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
  }

  selectedValue(event: MatAutocompleteSelectedEvent): void {
    const usr: User = {
      nam: event.option.value.nam,
      usrId: event.option.value.usrId
    };
    if (this.users.findIndex(p => p.usrId === usr.usrId) === -1) {
      this.users.push(usr);
      this.userInput.nativeElement.value = '';
      this.form.controls.personResponsible.setValue(null);
    }
  }

  close() {
    this.userInput.nativeElement.value = '';
    this.form.controls.personResponsible.setValue(null);
    this.form.controls.personResponsible.updateValueAndValidity();
  }

  removeOnFocusOut() {
    if (this.form.controls.personResponsible.value?.length <= 2) {
      this.close();
    }
  }

  addUsers(users: User[]) {
    users.forEach(u => this.users.push(u));
  }

  get getEnvironment() {
    return environment;
  }

  private checkRoles(): boolean {
    return this.roleGuardService.userRoles.includes(AG_ROLE.UK_MERCH_ADMIN)
      || (this.roleGuardService.userRoles.includes(AG_ROLE.UK_TERR_MANAGER)
        && this.form.controls.taskType.value === ActivityTypes.PERSONAL.valueOf())
      || this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCH_ADMIN)
      || this.roleGuardService.userRoles.includes(AG_ROLE.JS_IT)
      || (this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCHANDISER_MANAGERS)
        && this.form.controls.taskType.value === ActivityTypes.PERSONAL.valueOf());
  }
}
